import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { BaseParamsWithID, GenericBaseListParams, RequestFilter } from "src/app/core/models/base-requests.model";
import { GeneralResponse } from "src/app/core/models/base-responses.model";
import { EndpointsService } from "src/app/core/services/endpoint.service";
import { HttpService } from "src/app/core/services/http.service";
import { AppInterestAddParams, AppInterestAddResponse, AppInterestGetListResponse, AppInterestGetParams, AppInterestGetResponse } from "../model/app-interest.model";

@Injectable()
export class AppInterestService extends EndpointsService {
  private readonly POST_APP_INTEREST_ADD: string = `${this.API_URL}/AppInterestAdd`;
  private readonly POST_APP_INTEREST_DELETE: string = `${this.API_URL}/AppInterestDelete`;
  private readonly POST_APP_INTEREST_UPDATE: string = `${this.API_URL}/AppInterestUpdate`;
  private readonly POST_APP_INTEREST_GET: string = `${this.API_URL}/AppInterestGet`;
  private readonly POST_APP_INTEREST_GET_LIST: string = `${this.API_URL}/AppInterestGetList`;

  constructor(private httpService: HttpService) {
    super();
  }

  getAppInterestList(params: GenericBaseListParams<RequestFilter>): Observable<AppInterestGetListResponse> {
    return this.httpService.post_authorized(this.POST_APP_INTEREST_GET_LIST, params);
  }

  addAppInterest(params: AppInterestAddParams): Observable<AppInterestAddResponse> {
    return this.httpService.post_authorized(this.POST_APP_INTEREST_ADD, params);
  }

  updateAppInterest(params: AppInterestAddParams): Observable<AppInterestAddResponse> {
    return this.httpService.post_authorized(this.POST_APP_INTEREST_UPDATE, params);
  }

  removeAppInterest(params: BaseParamsWithID): Observable<GeneralResponse> {
    return this.httpService.post_authorized(this.POST_APP_INTEREST_DELETE, params);
  }

  getAppInterest(params: AppInterestGetParams): Observable<AppInterestGetResponse> {
    return this.httpService.post_authorized(this.POST_APP_INTEREST_GET, params);
  }

}
