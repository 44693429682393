import { BaseParams, BaseParamsWithID } from "src/app/core/models/base-requests.model";
import { GenericGeneralListResponse, GenericGeneralResponse } from "src/app/core/models/base-responses.model";
import { ItemData } from "src/app/modules/app-modules/models/common.model";


export class AppInterestData extends ItemData {
  AlphaProductID: number;
  AlphaProductName: string;
  AppID: number;
  FlagIsSystem: number;
  Identifier: string;
}

export class AppInterestGetListResponse extends GenericGeneralListResponse<AppInterestData> { }
export class AppInterestAddParams extends BaseParams {
  CloneID: number;
  Data: AppInterestData;
}
export class AppInterestAddResponse extends GenericGeneralResponse<AppInterestData> { }

export class AppInterestGetParams extends BaseParamsWithID { }

export class AppInterestGetResponse extends GenericGeneralResponse<AppInterestData> { }
