import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { AlphaTechResponseData, AlphaTechResponseDataList } from "src/app/shared/model/alphatech-response-data.model";
import { AlphaTechSubscribeUtils } from "src/app/shared/utils/alpha-tech-subscribe.utils";
import { GenericBaseListParams, RequestFilter, BaseParamsWithID, SORTING_POSITION_ASC } from "src/app/core/models/base-requests.model";
import { DEFAULT_PAGE_SIZE } from "src/app/core/consts/request-defaults";
import { AlphaTechServerMessage } from "src/app/shared/model/alphatech-server-message.model";
import { AppInterestService } from "../service/app-interest.service";
import { AppInterestAddParams, AppInterestData, AppInterestGetParams } from "../model/app-interest.model";

@Injectable()
export class AppInterestPresenter {

  constructor(
    private appInterestService: AppInterestService,
  ) { }

  getAppInterestList(
    appId: number,
    pageNumber: number,
    searchText?: string,
    takeOne?: boolean,
    addedItemsCount: number = 0,
    system?: number,
    deletedItemsCount: number = 0,
    appItemId?: number,
    forceAll?: boolean
  ): Observable<AlphaTechResponseDataList<AppInterestData>> {
    const params: GenericBaseListParams<RequestFilter> = new GenericBaseListParams<RequestFilter>();

    params.Take = takeOne ? 1 : DEFAULT_PAGE_SIZE;
    params.Position = pageNumber * DEFAULT_PAGE_SIZE + addedItemsCount - deletedItemsCount;
    params.Sorting = SORTING_POSITION_ASC;
    params.Filter.AppID = appId;
    params.Filter.AppItemID = appItemId;

    if (searchText) {
      params.Filter.Keyword = searchText;
    }
    params.ForceAll = forceAll ? 1 : 0;

    if (system === 1) {
      params.Filter.FlagIsSystem = system;
    } else {
      params.Filter.FlagIsSystem = system;
    }

    return AlphaTechSubscribeUtils.subscribeDataListResponseHandling(
      this.appInterestService.getAppInterestList(params)
    );
  }


  getAppInterest(id: number): Observable<AlphaTechResponseData<AppInterestData>> {
    const params: AppInterestGetParams = new AppInterestGetParams();
    params.ID = id;

    return AlphaTechSubscribeUtils.subscribeDataResponseHandling(
      this.appInterestService.getAppInterest(params)
    );
  }

  saveAppInterest(appInterestData: AppInterestData, appItemID: number, isEdit: boolean): Observable<AlphaTechResponseData<AppInterestData>> {
    const params: AppInterestAddParams = new AppInterestAddParams();
    params.Data = appInterestData;
    params.Data.AppItemID = appItemID;

    return AlphaTechSubscribeUtils.subscribeDataResponseHandling(
      isEdit ? this.appInterestService.updateAppInterest(params) : this.appInterestService.addAppInterest(params)
    );
  }

  cloneAppInterest(interestId: number): Observable<AlphaTechResponseData<AppInterestData>> {
    const params: AppInterestAddParams = new AppInterestAddParams();
    params.CloneID = interestId;

    return AlphaTechSubscribeUtils.subscribeDataResponseHandling(
      this.appInterestService.addAppInterest(params)
    );
  }

  deleteAppInterest(interestId: number): Observable<AlphaTechServerMessage> {
    const params: BaseParamsWithID = new BaseParamsWithID();
    params.ID = interestId;

    return AlphaTechSubscribeUtils.subscribeResponseHandling(
      this.appInterestService.removeAppInterest(params)
    );
  }
}
