import { PHInfo, VRInfo } from "./info.model";
import { BaseRecord } from "./base-responses.model";
import { BaseParams } from "./base-requests.model";

export class OrganizationInfo extends BaseRecord {
  AvailableLanguages: AvailableLanguage[] = [];
  Code: string;
  DefaultLanguageID: number;
  FlagRegisterEnabled: number;
  Logo: string;
  Name: string;
  OwnerID: number;
  PHInfo: PHInfo = new PHInfo();
  ParentID: number;
  PoweredBy: string;
  Status: number;
  SupportEmail: string;
  SupportPhone: string;
  Theme: OrganizationTheme[] = [];
  TimezoneOffset: number;
  VRInfo: VRInfo = new VRInfo();
  Website: string;
}

export class OrganizationTheme extends BaseRecord {
  ThemeItemName: string;
  ThemeItemValue: string;
}

export class AvailableLanguage {
  Code: string;
  CreatedByName: string;
  ID: number;
  ModifiedByName: string;
  Name: string;
  NativeName: string;
}

// --------------- Organization Update ---------------

export class OrganizationUpdateParams extends BaseParams {
  Data: OrganizationInfo = new OrganizationInfo();
  FlagForceLanguageUpdate: number;
}
